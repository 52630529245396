import { DanhMucTaiLieu, DanhSachDanhMucTaiLieu, DataTypeManagementDocument } from "./api/DocumentCatalogApi";
import { SelectOption } from "./api/RegionalApi";
import { Menu, QuyenMenu, CreatDanhMucTaiLieu } from "./api/RoleApi";
import env from "./env";
import * as XLSX from 'xlsx';

interface CheckValueOptions {
    isEmpty?: boolean,
    isNull?: boolean,
}

export interface Permission {
    id: number,
    name: string,
    url: string,
    icon: string,
    truy_cap: boolean
    them: boolean;
    sua: boolean;
    xoa: boolean;
    tai_ve: boolean;
    duyet: boolean
    email: boolean
}

export interface MenuCon {
    id: number;
    name: string;
    menu_url: string;
    truy_cap: boolean;
    them: boolean;
    sua: boolean;
    xoa: boolean;
    tai_len: boolean;
    tai_ve: boolean;
    duyet: boolean;
    email: boolean
    sub_menu: SubMenu[]
}

export interface SubMenu {
    id: number;
    name: string;
    sub_menu_url: string;
    truy_cap: boolean;
    them: boolean;
    sua: boolean;
    xoa: boolean;
    tai_len: boolean;
    tai_ve: boolean;
    duyet: boolean;
    email: boolean
}


export interface MenuApi {
    id: number,
    name: string,
    menu_url: string,
    icon: string,
    truy_cap: boolean;
    them: boolean;
    sua: boolean;
    xoa: boolean;
    tai_len: boolean;
    tai_ve: boolean;
    duyet: boolean;
    email: boolean;
    menu_con: MenuCon[]
}
export const paserParams = (params: any, valueIsFail: any, allow?: CheckValueOptions) => {
    for (let i in params) {
        if (typeof (params[i]) === 'object') {
            params[i] = paserParams(params[i], valueIsFail, allow)
        } else {
            params[i] = parseValue(params[i], valueIsFail, allow)
        }
    }
    return params
}

export const parseValue = (value: any, valueIsFail: any, allow?: CheckValueOptions) => {

    if (!allow?.isNull) {
        if (value === null) {
            return valueIsFail;
        }
        if (Number.isNaN(value)) {
            return valueIsFail;
        }
    }

    if (!allow?.isEmpty) {
        if (['string', 'object'].includes(typeof (value)) && value.length === 0) {
            return valueIsFail;
        }
    }
    return value;
}

export function getCurrentDateFormatted(date: Date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export function parseISODate(isoDateString: string): Date {
    return new Date(isoDateString);
}

export function setTypeDocument(type: string) {
    if (type === `pdf`) {
        return 1;
    }
    if (type === `doc` || type === `docx`) {
        return 2;
    }
    if (type === `xlsx` || type === `xls`) {
        return 3;
    }
    if (type === `csv`) {
        return 4;
    }
    if (type === `png`) {
        return 5
    }
    if (type === `jpg`) {
        return 6
    }
    if (type === `zip`) {
        return 8
    }
    if (type === `rar`) {
        return 9
    }
    if (type === `pptx`) {
        return 10
    }
    return 7
}

export function setDocument(type: number) {
    if (type === 1) {
        return `application/pdf`
    }
    if (type === 2) {
        return `application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
    }
    if (type === 3) {
        return `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
    }
    if (type === 4) {
        return `text/csv`;
    }
    if (type === 5) {
        return `text/png`;
    }
    return ``
}


export const dataRouterExcept = [
    "/home", "/internal-documents", "/search-document", "/", "/login", "/personal", "/personal-portal", "/personal-update", "/setup-email", "/setup-sendemail", "/news", "/signing-management", "/request-management", "/approval-management", "/approval-management-mobile-preview", "/reset"
]

export interface OptionSelect {
    value: number;
    title: string;
    children: OptionSelect[];
}


export function getFileNameFromContentDisposition(header: any) {
    const match = header.match(/filename[^;=\n]*=["'](.*?)["']/);
    if (match && match[1]) {
        return match[1];
    }
    return null;
}

export function convertPermissionToTree(permission: MenuApi[]) {
    const data: Permission[] = []
    if (permission.length > 0) {
        permission.forEach(element => {
            if (element.truy_cap) {
                if (element.menu_con.length > 0) {
                    if (element.truy_cap) {
                        element.menu_con.forEach(item => {
                            if (item.sub_menu.length > 0) {
                                item.sub_menu.map((row) => {
                                    if (row.truy_cap) {
                                        data.push({ id: row.id, icon: "", name: row.name, url: row.sub_menu_url, truy_cap: row.truy_cap, them: row.them, sua: row.sua, xoa: row.xoa, tai_ve: row.tai_ve, duyet: row.duyet, email: row.email })
                                    }
                                })
                            } else {
                                if (item.truy_cap) {
                                    data.push({ id: item.id, icon: "", name: item.name, url: item.menu_url, truy_cap: item.truy_cap, them: item.them, sua: item.sua, xoa: item.xoa, tai_ve: item.tai_ve, duyet: item.duyet, email: item.email })
                                }
                            }

                        })
                    }
                } else {
                    data.push({ id: element.id, icon: element.icon, name: element.name, url: element.menu_url, truy_cap: element.truy_cap, them: element.them, sua: element.sua, xoa: element.xoa, tai_ve: element.tai_ve, duyet: element.duyet, email: element.email })
                }
            }
        });
    }
    return data
}

export const findMenuItemById = (value: string, items: Permission[]): Permission | null => {
    const data = items.find((item) => item.url === value);
    if (data?.truy_cap) {
        return data;
    } else {
        return null;
    }
};

export function findTitleFromValue(value: number | null, treeData: OptionSelect[]): string {
    for (const node of treeData) {
        if (node.value === value) {
            return node.title;
        } else if (node.children && node.children.length > 0) {
            const foundTitle = findTitleFromValue(value, node.children);
            if (foundTitle) {
                return foundTitle;
            }
        }
    }
    return '';
};
export function findTitleKhoiNghiepVu(value: number | null, treeData: SelectOption[]): string {
    for (const node of treeData) {
        if (node.value === value) {
            return node.label;
        }
    }
    return '';
};

export function layDuoiFile(tenFile: string): string {
    const phanTachTenFile = tenFile.split('.');
    if (phanTachTenFile.length === 1 || (phanTachTenFile[0] === "" && phanTachTenFile.length === 2)) {
        return ""; // Trả về chuỗi rỗng nếu không có đuôi hoặc tên file bắt đầu bằng dấu chấm
    }
    return phanTachTenFile.pop()!.toLowerCase(); // Lấy đuôi file và chuyển về chữ thường
}
export function formattedTotal(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function convertBytes(byte: number): string {
    const units = ["B", "KB", "MB", "GB", "TB"];
    let unitIndex = 0;

    while (byte >= 1024 && unitIndex < units.length - 1) {
        byte /= 1024;
        unitIndex++;
    }
    return `${byte.toFixed(2)} ${units[unitIndex]}`;
}

export const units = [
    { value: 1, label: 'KB' },
    { value: 2, label: 'MB' },
];


export function convertBytesSystem(byte: number): { byte: string, unit: string } {
    const units = ["KB", "MB"];
    let unitIndex = 0;
    while (byte >= 1024 && unitIndex < units.length - 1) {
        byte /= 1024;
        unitIndex++;
    }
    return {
        byte: byte.toFixed(2),
        unit: units[unitIndex]
    };
}

export function convertToKilobytes(value: number, unit: string): number {
    const units: { [key: string]: number } = {
        KB: 1,
        MB: 1024,
    };
    return value * units[unit.toUpperCase()];
}
export interface DonVi {
    id: number
    ma_don_vi: string
    ten_don_vi: string
    parent_id: any
    status: boolean
    level: number
    loai_phong_ban: number | null,
    don_vi_con: DonVi[]
}

export interface LoaiPhongBan {
    id: number;
    ten_loai_phong_ban: string;
}


export interface DonVi2 {
    id: number;
    ten_don_vi: string;
    level: number;
    parent_id: number | null;
}
export function findParentIdLevelOne(units: DonVi2[], targetId: number | null, level: number): string | null {
    if (targetId === null) {
        return null;
    }
    const data = units.find((item) => item.id === targetId);
    if (data?.level === level) {
        return data.ten_don_vi;
    } else {
        return findParentIdLevelOne(units, data?.parent_id ?? null, level);
    }
}


export function findTenDonViById(units: DonVi[], targetId: number | null): { id: number | null, level: number, ten_don_vi: string } {
    for (const unit of units) {
        if (unit.id === targetId) {
            return { id: unit.parent_id, level: unit.level, ten_don_vi: unit.ten_don_vi };
        }
        if (unit.don_vi_con.length > 0) {
            const foundTenDonVi = findTenDonViById(unit.don_vi_con, targetId);
            if (foundTenDonVi) {
                return foundTenDonVi;
            }
        }
    }
    return { id: null, level: 0, ten_don_vi: "" };
}

export function flattenDonViArray(arr: DonVi[]): { id: number, ten_don_vi: string, level: number, parent_id: number | null, ma_don_vi: string, loai_phong_ban: number | null }[] {
    const result: { id: number, ten_don_vi: string, level: number, parent_id: number | null, ma_don_vi: string, loai_phong_ban: number | null }[] = [];

    function traverse(data: DonVi[]) {
        for (const item of data) {
            result.push({ id: item.id, ten_don_vi: item.ten_don_vi, level: item.level, parent_id: item.parent_id, ma_don_vi: item.ma_don_vi, loai_phong_ban: item.loai_phong_ban });
            if (item.don_vi_con && item.don_vi_con.length > 0) {
                traverse(item.don_vi_con);
            }
        }
    }
    traverse(arr);
    return result;
}

export async function checkPdfFileExistence(pdfUrl: string): Promise<boolean> {
    try {
        const response = await fetch(pdfUrl, { method: 'HEAD' });
        if (response.ok) {
            return true; // Tệp tồn tại và có thể truy cập được
        } else {
            return false; // Tệp không tồn tại hoặc không thể truy cập được
        }
    } catch (error) {
        throw new Error('Lỗi khi kiểm tra tệp PDF: ' + error); // Xảy ra lỗi khi kiểm tra tệp PDF
    }
}


export function flattenRoleAdd(arr: Menu[]): QuyenMenu[] {
    const result: QuyenMenu[] = []
    function traverse(data: Menu[]) {
        for (const item of data) {
            if ((item.truy_cap || item.them || item.sua || item.xoa || item.tai_len || item.tai_ve || item.duyet || item.email) && item.menu_id !== 999 && item.menu_id !== 1000) {
                result.push({ menu_id: item.menu_id, sub_menu_id: item.sub_menu_id, truy_cap: item.truy_cap, them: item.them, sua: item.sua, xoa: item.xoa, tai_len: item.tai_len, tai_ve: item.tai_ve, chia_se: item.chia_se, duyet: item.duyet, email: item.email });
            }

            if (item.children && item.children.length > 0) {
                traverse(item.children);
            }
        }
    }
    traverse(arr);
    return result;
}

export function flattenDanhMuc(arr: Menu[]): CreatDanhMucTaiLieu[] {
    const result: CreatDanhMucTaiLieu[] = []
    function traverse(data: Menu[]) {
        for (const item of data) {
            if (item.truy_cap || item.them || item.sua || item.xoa) {
                result.push({truy_cap: item.truy_cap, them: item.them, sua: item.sua, xoa: item.xoa });
            }
            if (item.children && item.children.length > 0) {
                traverse(item.children);
            }
        }
    }
    traverse(arr);
    return result;
}

export function downloadFile(base64String: string, ten: string) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
    }
    const blob = new Blob([new Uint8Array(byteArrays)], { type: 'application/octet-stream' });
    // Create a download link and trigger the download
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = ten; // Replace with the desired file name and extension
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

export function downloadTaiLieu(tai_lieu: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        try {
            const href = `${env.process.env.PDF_URL}${tai_lieu}`;
            checkPdfFileExistence(href)
                .then((fileExists: boolean) => {
                    if (fileExists) {
                        fetch(href)
                            .then(response => response.blob())
                            .then(async blob => {
                                const url = URL.createObjectURL(blob);
                                const a = document.createElement("a");
                                a.href = url;
                                a.download = tai_lieu;
                                a.target = "_blank"; // Open in a new tab/window
                                a.click();
                                setTimeout(function () {
                                    a.remove();
                                    URL.revokeObjectURL(url);
                                    resolve(true);
                                }, 100);
                            })
                            .catch((error: Error) => {
                                reject(error);
                            });
                    } else {
                        resolve(false);
                    }
                })
                .catch((error: Error) => {
                    reject(error);
                });
        } catch (error) {
            console.log("222");
            reject(error);
        }
    });
}

export function downloadBaoCao(bao_cao: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        try {
            const href = `${env.process.env.REPORT_URL}${bao_cao}`;
            checkPdfFileExistence(href)
                .then((fileExists: boolean) => {
                    if (fileExists) {
                        fetch(href)
                            .then(response => response.blob())
                            .then(async blob => {
                                const url = URL.createObjectURL(blob);
                                const a = document.createElement("a");
                                a.href = url;
                                a.download = bao_cao;
                                a.target = "_blank"; // Open in a new tab/window
                                a.click();
                                setTimeout(function () {
                                    a.remove();
                                    URL.revokeObjectURL(url);
                                    resolve(true);
                                }, 100);
                            })
                            .catch((error: Error) => {
                                reject(error);
                            });
                    } else {
                        resolve(false);
                    }
                })
                .catch((error: Error) => {
                    reject(error);
                });
        } catch (error) {
            console.log("222");
            reject(error);
        }
    });
}


export function copyLink(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
        try {
            const tempTextArea = document.createElement('textarea');
            tempTextArea.value = window.location.href;
            document.body.appendChild(tempTextArea);
            tempTextArea.select();
            tempTextArea.setSelectionRange(0, 99999);
            document.execCommand('copy');
            document.body.removeChild(tempTextArea);
            resolve(true);
        } catch (error) {
            console.error('Error copying link:', error);
            resolve(false);
        }
    });
}

export function checkTaiLieu(tai_lieu: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        try {
            const href = `${env.process.env.PDF_URL}${tai_lieu}`;
            checkPdfFileExistence(href)
                .then((fileExists: boolean) => {
                    if (fileExists) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch((error: Error) => {
                    reject(error);
                });
        } catch (error) {
            reject(error);
        }
    });
}

export function checkTaiLieuQTKH(tai_lieu: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
        try {
            const href = `${env.process.env.PDF_URL_QTKH}${tai_lieu}`;
            checkPdfFileExistence(href)
                .then((fileExists: boolean) => {
                    if (fileExists) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch((error: Error) => {
                    reject(error);
                });
        } catch (error) {
            reject(error);
        }
    });
}

export function exportExcel(convertData: any, name: string): boolean {
    try {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData);
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

        const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
            const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
            const link: HTMLAnchorElement = document.createElement('a');
            link.href = window.URL.createObjectURL(data);
            link.download = fileName;
            link.click();
        };

        saveAsExcel(excelBuffer, name);
        return true; // Export successful
    } catch (error) {
        console.error('Error exporting Excel:', error);
        return false; // Export failed
    }
}

export const optionPagination = [
    {
        value: 10,
        label: '10',
    },
    {
        value: 20,
        label: '20',
    },
    {
        value: 50,
        label: '50',
    },
    {
        value: 100,
        label: '100',
    },
]
export function convertToDate(dateString: string) {
    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Tháng trong Date bắt đầu từ 0 (0 là tháng 1)
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
}

export const listDataMonth = [
    {
        value: 1,
        label: 'Tháng 1',
    },
    {
        value: 2,
        label: 'Tháng 2',
    },
    {
        value: 3,
        label: 'Tháng 3',
    },
    {
        value: 4,
        label: 'Tháng 4',
    },
    {
        value: 5,
        label: 'Tháng 5',
    },
    {
        value: 6,
        label: 'Tháng 6',
    },
    {
        value: 7,
        label: 'Tháng 7',
    },
    {
        value: 8,
        label: 'Tháng 8',
    },
    {
        value: 9,
        label: 'Tháng 9',
    },
    {
        value: 10,
        label: 'Tháng 10',
    },
    {
        value: 11,
        label: 'Tháng 11',
    },
    {
        value: 12,
        label: 'Tháng 12',
    },
]

export function checkValidateEmail(email: string) {
    const regex = /\b[A-Za-z0-9._%+-]+@gmail\.com\b/;
    // const regex = /\b[A-Za-z0-9._%+-]+@thaco\.com\.vn\b/;
    if (!regex.test(email)) {
        return false
    } else {
        return true
    }
}

export function convertToSlug(text: string): string {
    // Chuyển hết sang chữ thường
    text = text.toLowerCase();
    // xóa dấu
    text = text
        .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
    // Thay ký tự đĐ
    text = text.replace(/[đĐ]/g, "d");
    // Xóa ký tự đặc biệt
    text = text.replace(/([^0-9a-z-\s])/g, "");
    // Xóa khoảng trắng thay bằng ký tự -
    text = text.replace(/(\s+)/g, "-");

    // Xóa ký tự - liên tiếp
    text = text.replace(/-+/g, "-");

    // xóa phần dư - ở đầu & cuối
    text = text.replace(/^-+|-+$/g, "");

    // return
    return text;
}

export const danhSachBinhLuan = [{ value: 1, label: 'Cho phép' }, { value: 2, label: 'Không cho phép' }]

export function parseHTML(htmlString: string) {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export function chuyenDoiCauTruc(danhMucs: DanhSachDanhMucTaiLieu[]): DataTypeManagementDocument[] {
    return danhMucs.sort((a, b) => a.stt - b.stt).map(danhMuc => ({
        key: danhMuc.id.toString(),
        id: danhMuc.id,
        level: danhMuc.level,
        ma_danh_muc: danhMuc.ma_danh_muc,
        ten_danh_muc: danhMuc.ten_danh_muc,
        trang_thai: danhMuc.status == 0 ? false : true,
        admin_id: danhMuc.admin_id,
        show: danhMuc.type == 0 ? false : true,
        children: chuyenDoiCauTruc(danhMuc.danh_muc_con)
    }));
}


export function getAllRecords(treeData: DataTypeManagementDocument[]): DataTypeManagementDocument[] {
    let allRecords: DataTypeManagementDocument[] = [];
    treeData.forEach(node => {
        allRecords.push(node);
        if (node.children.length > 0) {
            allRecords = allRecords.concat(getAllRecords(node.children));
        } 
    });
    return allRecords;
}

export const filterTreeData = (input: string, treeData: DataTypeManagementDocument[]): DataTypeManagementDocument[] => {
    const filteredTree: DataTypeManagementDocument[] = [];

    treeData.forEach(node => {
        const filteredNode: DataTypeManagementDocument = { ...node };
        // Nếu node có children, lọc các children trước
        if (node.children) {
            filteredNode.children = filterTreeData(input, node.children);
        }
        // Kiểm tra xem node có phải là một phần của kết quả lọc không
        const nodeTitle = typeof node.ten_danh_muc === 'string' ? node.ten_danh_muc.toLowerCase() : '';
        const isMatched = nodeTitle.includes(input.toLowerCase());

        // Nếu node hoặc bất kỳ children nào của nó phù hợp với bộ lọc, giữ lại node đó
        if (isMatched || (filteredNode.children && filteredNode.children.length > 0)) {
            filteredTree.push(filteredNode);
        }

    });

    return filteredTree;
};

export function exportExcelMore(convertData: any, convertData2: any, name: string): boolean {
    try {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData);
        const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData2);
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Quyền');
        XLSX.utils.book_append_sheet(workbook, worksheet2, 'Người dùng');

        const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

        const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
            const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
            const link: HTMLAnchorElement = document.createElement('a');
            link.href = window.URL.createObjectURL(data);
            link.download = fileName;
            link.click();
        };

        saveAsExcel(excelBuffer, name);
        return true; // Export successful
    } catch (error) {
        console.error('Error exporting Excel:', error);
        return false; // Export failed
    }
}

export function getAllRecordsKey(treeData: DataTypeManagementDocument[]): string[] {
    let allRecords: string[] = [];
    treeData.forEach(node => {
        allRecords.push(node.key);
        if (node.children.length > 0) {
            allRecords = allRecords.concat(getAllRecordsKey(node.children));
        } 
    });
    return allRecords;
}

export function findDanhMucDonVi(treeData: DanhMucTaiLieu[], don_vi: number): number | null {
    for (const node of treeData) {
        if (node.don_vi_ban_hanh === don_vi) {
            return node.id;
        } else if (node.danh_muc_con && node.danh_muc_con.length > 0) {
            const foundTitle = findDanhMucDonVi(node.danh_muc_con, don_vi);
            if (foundTitle) {
                return foundTitle;
            }
        }
    }
    return null;
}

export function updateKeyMenu(data: Menu[]) {
    let keyCount = 1;
    // Hàm đệ quy để thêm key cho mảng đệ quy
    const addKeys = (items: Menu[]) => {
        items.forEach(item => {
            item.key = keyCount++;
            if (item.children) {
                addKeys(item.children);
            }
        });
    };
    addKeys(data);
    return data;
}
export function exportExcelMoreDocument(convertData: any, convertData2: any, name: string): boolean {
    try {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData);
        const worksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData2);
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Tài liệu');
        XLSX.utils.book_append_sheet(workbook, worksheet2, 'Total');

        const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

        const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
            const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
            const link: HTMLAnchorElement = document.createElement('a');
            link.href = window.URL.createObjectURL(data);
            link.download = fileName;
            link.click();
        };

        saveAsExcel(excelBuffer, name);
        return true; // Export successful
    } catch (error) {
        console.error('Error exporting Excel:', error);
        return false; // Export failed
    }
}