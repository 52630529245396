import { Input, Modal } from 'antd'
import './styles.css'
import { CloseOutlined, CopyOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import * as SystemConfigurationApi from '../../../../../api/SystemConfigurationApi/index';
import { LoadingSpin } from '../../../../../components/LoadingSpin';
import { useEffect, useState } from 'react';
import useToast from '../../../../../hook/useToast';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { click } from '@testing-library/user-event/dist/click';

const { TextArea } = Input;
export interface ModalProps {
    open: boolean;
    handleClose: () => void;
    dataChiTiet: SystemConfigurationApi.EmailTemplate | undefined
    listParamDocument: SystemConfigurationApi.Param[]
    listParamReport: SystemConfigurationApi.Param[]
    listParamDuThao: SystemConfigurationApi.Param[]
}

export default function SetUpNotificationEmail(modal: ModalProps) {
    let errorsObj = {
        tieu_de: "",
        noi_dung: "",
    };
    const { open, handleClose, dataChiTiet, listParamDocument, listParamReport, listParamDuThao } = modal
    const [loadingSpin, setLoadingSpin] = useState<boolean>(false)
    const [errors, setErrors] = useState(errorsObj);
    const [idEmail, setIdEmail] = useState<number>(0);
    const [tieuDe, setTieuDe] = useState<string>("");
    const [noiDung, setNoiDung] = useState<string>("");
    const [type, setType] = useState<number | null>(null)
    const pushAlert = useToast();

    const UpdateSystem = async (e: any) => {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (tieuDe === "") {
            errorObj.tieu_de = "Vui lòng nhập tiêu đề";
            error = true;
        }
        if (noiDung.trim() === "") {
            errorObj.noi_dung = "Vui lòng nhập nội dung";
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return null;
        } else {
            try {
                const result = await SystemConfigurationApi.updateEmailTemplate(idEmail, tieuDe, noiDung);
                setLoadingSpin(true);
                if (result.success) {
                    pushAlert("Cập nhật thành công!", "success", 2000)
                    handleClose();
                    setLoadingSpin(false);
                } else {
                    pushAlert('Cập nhật không thành công' ?? "", "error", 2000)
                    setLoadingSpin(false);
                }
            } catch (error: any) {
                setLoadingSpin(false);
                pushAlert(error.message ?? "", "error", 2000)
            }
        }
    };

    function uploadPlugin(editor: any) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
            return uploadAdapter(loader);
        };
    }

    function uploadAdapter(loader: any) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: any) => {
                    });
                });
            }
        };
    }
    useEffect(() => {
        setIdEmail(dataChiTiet?.id ?? 0);
        setTieuDe(dataChiTiet?.tieu_de ?? "");
        setNoiDung(dataChiTiet?.noi_dung ?? "");
        setType(dataChiTiet?.type ??null);
        setLoadingSpin(false)
    }, [open, dataChiTiet]);
    const copytext = (text: string) => () => {
        navigator.clipboard.writeText(text);
        pushAlert("Copy thành công", "success", 2000)
    }
    const [activeParam, setActiveParam] = useState<boolean>(false)
    useEffect(() => {
        document.addEventListener('click', function (event: any) {
            if (event.target.matches('.mail-params , .mail-params  *') === false) {
                setActiveParam(false)
            }
        })
    }, [])
    return (
        <Modal
            title={
                <div className='flex items-center gap-3 pb-3'>
                    <div className='border-r-EEEEEE cursor' onClick={handleClose}>
                        <CloseOutlined className='text-[#8E8E8E] pr-4' />
                    </div>
                    {dataChiTiet?.ten}
                </div>
            }
            centered
            open={open}
            onCancel={handleClose}
            footer={[]}
            className="custom-modal"
            width={1000}
            closeIcon={false}
        >
            <div className="flex flex-col ">
                <div className='flex flex-col py-[18px]  border-b-t-EEEEEE gap-4'>
                    <div className='head-form mail-template-content'>
                        <div className='input-title'>
                            <Input placeholder="Tiêu đề" value={tieuDe} onChange={(e) => setTieuDe(e.target.value)} />
                            {errors.tieu_de && (
                                <div className="text-danger fs-12">
                                    {errors.tieu_de}
                                </div>
                            )}
                        </div>
                        <div className={`mail-params ${activeParam ? 'active' : ''}`}>
                            <div onClick={() => setActiveParam(!activeParam)} className='lp-title text-save text-white rounded-[4px] overflow-hidden w-[106px] bg-[#096DD9] ant-btn ant-btn-text h-full w-full text-white  flex items-center custome-btne'>
                                <span>Danh sách params</span>
                                {activeParam === false ? <DownOutlined className='icon-d' /> : <UpOutlined className='icon-d' />}
                            </div>
                            <div className='lot-x'>
                                <div className='list-data'>
                                    {type && type === 0 ? listParamReport && listParamReport.map((item, index) => {
                                        return (
                                            <div className='lp-item' key={index}>
                                                <div className='lb-info'>
                                                    <div className='label'>{item.title}</div>
                                                    <div className='value'>{item.value}</div>
                                                </div>
                                                <div className='icon' onClick={copytext(item.value)}>
                                                    <CopyOutlined />
                                                </div>
                                            </div>
                                        )
                                    }) : type && type === 1 ? listParamDocument && listParamDocument.map((item, index) => {
                                        return (
                                            <div className='lp-item' key={index}>
                                                <div className='lb-info'>
                                                    <div className='label'>{item.title}</div>
                                                    <div className='value'>{item.value}</div>
                                                </div>
                                                <div className='icon' onClick={copytext(item.value)}>
                                                    <CopyOutlined />
                                                </div>
                                            </div>
                                        )
                                    }) : listParamDuThao && listParamDuThao.map((item, index) => {
                                        return (
                                            <div className='lp-item' key={index}>
                                                <div className='lb-info'>
                                                    <div className='label'>{item.title}</div>
                                                    <div className='value'>{item.value}</div>
                                                </div>
                                                <div className='icon' onClick={copytext(item.value)}>
                                                    <CopyOutlined />
                                                </div>
                                            </div>
                                        )
                                    }) }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-textarea'>
                        <CKEditor
                            editor={ClassicEditor}
                            data={noiDung}
                            onChange={(event, editor) => {
                                setNoiDung(editor.getData())
                            }}
                            onBlur={(event, editor) => {
                                console.log(event);

                            }}
                            onReady={ClassicEditor => { }}
                            config={{
                                extraPlugins: [uploadPlugin],
                                mediaEmbed: { previewsInData: true }
                            }}
                        />
                    </div>
                    {errors.noi_dung && (
                        <div className="text-danger fs-12">
                            {errors.noi_dung}
                        </div>
                    )}
                </div>
                <div className='border-t-EEEEEE pt-4'>
                    <div className="flex justify-end items-center flex-wrap">
                        <div className="w-full flex items-center gap-8 justify-end">
                            <span onClick={handleClose} className='  text-sm font-[500] cursor-pointer'>Hủy</span>
                            <div className={`text-save text-white rounded-[4px] overflow-hidden w-[106px]  bg-[#096DD9] ${loadingSpin ? ' pointer-events-none' : ''}`} onClick={UpdateSystem}>
                                <LoadingSpin open={loadingSpin} button="Lưu" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
