/* eslint-disable import/no-anonymous-default-export */

// export const API_URL = "http://localhost:5001/";
// export const IMG_URL = "http://localhost:5001/uploads/images/"
// export const PDF_URL = "http://localhost:5001/uploads/documents/"
// export const IMAGE_AVA_URL = "http://localhost:5001/uploads/avatars/"
// export const IMAGE_LIB_URL = "http://localhost:5001/uploads/libs/"
// export const REPORT_URL = "http://localhost:5001/uploads/reports/"
export const API_URL = "http://bdoc.bdata.link:5001/"
export const IMG_URL = "http://bdoc.bdata.link:5001/uploads/images/"
export const PDF_URL = "http://bdoc.bdata.link:5001/uploads/documents/"
export const IMAGE_AVA_URL = "http://bdoc.bdata.link:5001/uploads/avatars/"
export const IMAGE_LIB_URL = "http://bdoc.bdata.link:5001/uploads/libs/"
export const REPORT_URL = "http://bdoc.bdata.link:5001/uploads/reports/"
export const PDF_URL_QTKH = "https://qtkh.thaco.com.vn/Upload/MISFile/"
export const LIMIT_TIME = 1000;

export default {
  process: {
    env: {
      API_URL,
      LIMIT_TIME,
      IMG_URL,
      PDF_URL,
      IMAGE_AVA_URL,
      REPORT_URL,
      PDF_URL_QTKH, 
      IMAGE_LIB_URL
    },
  },
};
